import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import {
  withPrismicUnpublishedPreview,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import { linkResolver } from '@/prismic/utils/linkResolver'
import ErrorTemplate from '@/templates/Error'

import PagePrismic from '@/prismic/types/Page.prismic'
import BlogPostPrismic from '@/prismic/types/BlogPost.prismic'
import FaqPrismic from '@/prismic/types/Faq.prismic'

const NotFoundPage = ({ path }) => (
  <ErrorTemplate title="Page Not found" path={path}>
    <p>
      If you entered a web address please check it was correct, <br />
      or <Link to="/">click here</Link> to go to the homepage.
    </p>
  </ErrorTemplate>
)

NotFoundPage.propTypes = {
  path: PropTypes.string,
}

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withPrismicUnpublishedPreview(NotFoundPage, [
  {
    repositoryname: process.env.GATSBY_PRISMIC_REPO,
    linkResolver,
    componentResolver: componentResolverFromMap({
      page: PagePrismic,
      blog_post: BlogPostPrismic,
      faq: FaqPrismic,
    }),
  },
])
